import revive_payload_client_AzmEbF6kG1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Y9vPv338qf from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wBnMEdXMnX from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_EtPZR8oEEi from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kn1wq5YeWx from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eLQvOGhDY7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bliqT1NOCL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_fzkK3OHpL8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_u7hV1bP1qC from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.2_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._4kbstwdq2qvijkw44ezcchhwvi/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_AzmEbF6kG1,
  unhead_Y9vPv338qf,
  router_wBnMEdXMnX,
  payload_client_EtPZR8oEEi,
  navigation_repaint_client_kn1wq5YeWx,
  check_outdated_build_client_eLQvOGhDY7,
  chunk_reload_client_bliqT1NOCL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fzkK3OHpL8,
  plugin_ywJDomcNte,
  tooltip_u7hV1bP1qC,
  bugsnag_tkMQfOjcVZ
]